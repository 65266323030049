/* You can add global styles to this file, and also import other style files */
@import 'theme/variables';
@import 'theme/components';
@import 'theme/dragdrop';
@import '~swiper/swiper-bundle';

@import '~swiper/swiper-bundle';

@import '~@typeform/embed/build/css/popup.css';

/**
* Initially generated theme by Material Theme Generator (Modified by ›xelasoft)
* https://materialtheme.arcsine.dev
*/

// @import "../node_modules/angular-calendar/css/angular-calendar.css";
@import '~angular-calendar/css/angular-calendar.css';
@import '~@mat-datetimepicker/core/datetimepicker/datetimepicker-theme.scss';

@import '~@angular/material/theming';
// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.

// Fonts
@import 'https://fonts.googleapis.com/css?family=Material+Icons+Outlined';
// @import url('https://fonts.googleapis.com/css?family=Oswald:300,400,500');

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600&family=Poppins:wght@300;400;500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600&family=Nunito+Sans:wght@300;400;600&family=Poppins:wght@300;400;500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@300;400;500;600&display=swap');
// @import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600&display=swap');

$header-font: 'Poppins';
$content-font: 'Source Sans Pro';

$fontConfig: (
  display-4: mat-typography-level(112px, 112px, 500, $header-font),
  display-3: mat-typography-level(56px, 56px, 400, $header-font),
  display-2: mat-typography-level(45px, 48px, 400, $header-font),
  display-1: mat-typography-level(34px, 40px, 400, $header-font),
  headline: mat-typography-level(24px, 32px, 500, $header-font),
  title: mat-typography-level(20px, 32px, 500, $header-font),
  subheading-2: mat-typography-level(16px, 20px, 400, $header-font),
  subheading-1: mat-typography-level(15px, 24px, 500, $header-font),
  body-2: mat-typography-level(16px, 24px, 500, $content-font),
  body-1: mat-typography-level(16px, 20px, 400, $content-font),
  caption: mat-typography-level(12px, 20px, 400, $content-font),
  button: mat-typography-level(14px, 14px, 500, $header-font),
  input: mat-typography-level(inherit, 1.125, 400, $content-font),
);

// Foreground Elements

// Light Theme Text
$dark-text: #0e0f0f;
$dark-primary-text: rgba($dark-text, 0.87);
$dark-accent-text: rgba($dark-primary-text, 0.54);
$dark-disabled-text: rgba($dark-primary-text, 0.38);
$dark-dividers: rgba($dark-primary-text, 0.12);
$dark-focused: rgba($dark-primary-text, 0.12);

$mat-light-theme-foreground: (
  base: black,
  divider: $dark-dividers,
  dividers: $dark-dividers,
  disabled: $dark-disabled-text,
  disabled-button: rgba($dark-text, 0.26),
  disabled-text: $dark-disabled-text,
  elevation: black,
  secondary-text: $dark-accent-text,
  hint-text: $dark-disabled-text,
  accent-text: $dark-accent-text,
  icon: $dark-accent-text,
  icons: $dark-accent-text,
  text: $dark-primary-text,
  slider-min: $dark-primary-text,
  slider-off: rgba($dark-text, 0.26),
  slider-off-active: $dark-disabled-text,
);

// Dark Theme text
$light-text: #ffffff;
$light-primary-text: $light-text;
$light-accent-text: rgba($light-primary-text, 0.7);
$light-disabled-text: rgba($light-primary-text, 0.5);
$light-dividers: rgba($light-primary-text, 0.12);
$light-focused: rgba($light-primary-text, 0.12);

$mat-dark-theme-foreground: (
  base: $light-text,
  divider: $light-dividers,
  dividers: $light-dividers,
  disabled: $light-disabled-text,
  disabled-button: rgba($light-text, 0.3),
  disabled-text: $light-disabled-text,
  elevation: black,
  hint-text: $light-disabled-text,
  secondary-text: $light-accent-text,
  accent-text: $light-accent-text,
  icon: $light-text,
  icons: $light-text,
  text: $light-text,
  slider-min: $light-text,
  slider-off: rgba($light-text, 0.3),
  slider-off-active: rgba($light-text, 0.3),
);

// Background config
// Light bg
$light-background: #f5f5f5;
$light-bg-darker-5: darken($light-background, 5%);
$light-bg-darker-10: darken($light-background, 10%);
$light-bg-darker-20: darken($light-background, 20%);
$light-bg-darker-30: darken($light-background, 30%);
$light-bg-lighter-5: lighten($light-background, 5%);
$dark-bg-tooltip: lighten(#202222, 20%);
$dark-bg-alpha-4: rgba(#202222, 0.04);
$dark-bg-alpha-12: rgba(#202222, 0.12);

$mat-light-theme-background: (
  background: $light-background,
  status-bar: $light-bg-darker-20,
  app-bar: $light-bg-darker-5,
  hover: $dark-bg-alpha-4,
  card: $light-bg-lighter-5,
  dialog: $light-bg-lighter-5,
  tooltip: $dark-bg-tooltip,
  disabled-button: $dark-bg-alpha-12,
  raised-button: $light-bg-lighter-5,
  focused-button: $dark-focused,
  selected-button: $light-bg-darker-20,
  selected-disabled-button: $light-bg-darker-30,
  disabled-button-toggle: $light-bg-darker-10,
  unselected-chip: $light-bg-darker-10,
  disabled-list-option: $light-bg-darker-10,
);

// Dark bg
$dark-background: #202222;
$dark-bg-lighter-5: lighten($dark-background, 5%);
$dark-bg-lighter-10: lighten($dark-background, 10%);
$dark-bg-lighter-20: lighten($dark-background, 20%);
$dark-bg-lighter-30: lighten($dark-background, 30%);
$light-bg-alpha-4: rgba(#f5f5f5, 0.04);
$light-bg-alpha-12: rgba(#f5f5f5, 0.12);

// Background palette for dark themes.
$mat-dark-theme-background: (
  background: $dark-background,
  status-bar: $dark-bg-lighter-20,
  app-bar: $dark-bg-lighter-5,
  hover: $light-bg-alpha-4,
  card: $dark-bg-lighter-5,
  dialog: $dark-bg-lighter-5,
  tooltip: $dark-bg-lighter-20,
  disabled-button: $light-bg-alpha-12,
  raised-button: $dark-bg-lighter-5,
  focused-button: $light-focused,
  selected-button: $dark-bg-lighter-20,
  selected-disabled-button: $dark-bg-lighter-30,
  disabled-button-toggle: $dark-bg-lighter-10,
  unselected-chip: $dark-bg-lighter-20,
  disabled-list-option: $dark-bg-lighter-10,
);

// Compute font config
@include mat-core($fontConfig);

// Theme Config
html,
body {
  height: 100%;
}
body {
  --primary-color-rgb: 127, 117, 110;
  --primary-color: #7f756e;
  --primary-lighter-color: #d9d6d4;
  --primary-darker-color: #625851;
  --text-primary-color: #{$dark-primary-text};
  --text-primary-lighter-color: #{$dark-primary-text};
  --text-primary-darker-color: #{$light-primary-text};

  --accent-color-rgb: 35, 21, 34;
  --accent-color: #685944;
  --accent-lighter-color: #d2cdc7;
  --accent-darker-color: #4b3e2d;
  --text-accent-color: #{$light-primary-text};
  --text-accent-lighter-color: #{$dark-primary-text};
  --text-accent-darker-color: #{$light-primary-text};

  --accent2-color: #d0e36d;
  --accent2-color-rgb: 208, 227, 109;
  --accent2-lighter-color: #ebf4c3;
  --accent2-darker-color: #aac526;
  --text-accent2-color: #{$light-primary-text};
  --text-accent2-lighter-color: #{$dark-primary-text};
  --text-accent2-darker-color: #{$light-primary-text};

  --zoom-color: #2d8cff;
  --zoom-color-rgb: 45, 140, 255;

  --info-color: rgb(44, 99, 166);
  --info-color-rgb: 44, 99, 166;
  --success-color: hsl(160, 68%, 22%);
  --success-color-rgb: 18, 95, 69;
  --warn-color: rgb(174, 18, 19);
  --warn-color-rgb: 174, 18, 19;
  --warn-lighter-color: #f7625b;
  --warn-darker-color: #d84740;
  --text-warn-color: #{$light-primary-text};
  --text-warn-lighter-color: #{$dark-primary-text};
  --text-warn-darker-color: #{$light-primary-text};
  --warning-color: #df8436;

  margin: 0;
}

$mat-primary: (
  // Cannot use var() because of datatimepicker's usage of this var.
  main: #685944,
  lighter: var(--primary-lighter-color),
  darker: var(--primary-darker-color),
  200: var(--primary-color),
  contrast: (
    main: $light-primary-text,
    lighter: $dark-primary-text,
    darker: $light-primary-text,
  )
);

$mat-accent: (
  main: var(--accent-color),
  lighter: var(--accent-lighter-color),
  darker: var(--accent-darker-color),
  200: var(--accent-color),
  contrast: (
    main: $light-accent-text,
    lighter: $dark-accent-text,
    darker: $light-accent-text,
  ),
);

$mat-warn: (
  main: var(--warn-color),
  lighter: var(--warn-lighter-color),
  darker: var(--warn-darker-color),
  200: var(--warn-color),
  contrast: (
    main: $light-primary-text,
    lighter: $dark-primary-text,
    darker: $light-primary-text,
  ),
);

$theme-primary: mat-palette($mat-primary, main, lighter, darker);
$theme-accent: mat-palette($mat-accent, main, lighter, darker);
$theme-warn: mat-palette($mat-warn, main, lighter, darker);

$theme: mat-light-theme($theme-primary, $theme-accent, $theme-warn);
$altTheme: mat-dark-theme($theme-primary, $theme-accent, $theme-warn);

// Theme Init
@include angular-material-theme($theme);
@include components-theme($theme);
@include utilities-theme($theme);
@include mat-datetimepicker-theme($theme);

.theme-dark {
  @include angular-material-theme($altTheme);
  @include components-theme($altTheme);
  @include utilities-theme($altTheme);
}

// Customizations
// @import 'ace-css';

.mat-tooltip {
  padding: 4px 10px;
}

.mat-datetimepicker-calendar-header {
  // display: none;
}

.mat-drawer-backdrop.mat-drawer-shown {
  background-color: rgba(0, 0, 0, 0.3);
  backdrop-filter: blur(5px);
}

// .mat-display-1,
// .mat-display-2,
// .mat-display-3,
// .mat-display-4,
// .mat-h1,
// .mat-headline,
// .mat-h2,
// .mat-title,
// .mat-h3,
// .mat-subheading-2,
// .mat-h4,
// .mat-subheading-1,
// .mat-button-base,
// .mat-tab-link,
// .mat-dialog-title,
// .mat-tab-label,
// h1,
// h2,
// h3,
// h4,
// h5,
// h6 {
//   // text-transform: uppercase;
// }

.mat-button-base,
.mat-tab-link,
.mat-tab-label {
  // text-transform: uppercase;
}

.mat-button-base:not(.mat-icon-button) {
  display: block;
  .mat-button-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    height: 100%;
    .mat-icon {
      font-size: 18px;
      width: 18px;
      height: 18px;
      line-height: 18px;
    }
  }
}

.mat-step-icon-content .mat-icon {
  line-height: 16px;
}

body .mat-expansion-panel {
  border-radius: 8px;
  box-shadow: 0px 5px 5px -3px rgba(119, 119, 119, 0.16), 0px 8px 10px 1px rgba(119, 119, 119, 0.112),
    0px 3px 14px 2px rgba(119, 119, 119, 0.096) !important;
  &:not(.mat-expanded):not(:first-of-type) {
    clip-path: inset(0 -16px -16px -16px);
  }
  &.mat-expanded + .mat-expansion-panel {
    clip-path: none;
  }
  .mat-content {
    overflow: visible;
  }
}

button {
  outline: none;
}

.mat-flat-button.mat-primary:not(.mat-button-disabled).glow {
  box-shadow: 0 3px 6px rgba(var(--primary-color-rgb), 0.16), 0 3px 6px rgba(var(--primary-color-rgb), 0.23) !important;
}
.mat-flat-button.mat-accent:not(.mat-button-disabled).glow {
  box-shadow: 0 3px 6px rgba(var(--accent-color-rgb), 0.16), 0 3px 6px rgba(var(--accent-color-rgb), 0.23) !important;
}

.mat-icon-button .mat-icon {
  // margin-top: -3px;
}

.mat-icon {
  line-height: 24px;
  font-size: 24px;
}

.mat-chip {
  .mat-chip-trailing-icon.mat-icon,
  .mat-chip-remove.mat-icon {
    line-height: 18px;
  }
}

.mat-ink-bar {
  height: 3px !important;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
}

.material-icons {
  font-family: 'Material Icons Outlined', 'Material Icons';
  .mat-badge-content {
    font-family: 'PT Mono';
  }
}

@media screen and (max-width: 599px) {
  .cdk-overlay-pane {
    // max-width: calc(100vw - 32px) !important;
    .mat-dialog-content {
      max-height: 70vh;
    }
  }
}

.cdk-overlay-pane {
  max-height: calc(100vh - 16px) !important;
}

.mat-snack-bar-container {
  &.mat-primary {
    color: white;
    background-color: rgba(var(--primary-color-rgb), 1);
    .mat-simple-snackbar-action {
      color: var(--text-primary-color);
    }
    .mat-button-wrapper {
      color: white;
    }
  }
  &.mat-accent {
    color: var(--text-accent-color);
    background-color: rgba(var(--accent-color-rgb), 1);
    .mat-simple-snackbar-action {
      color: var(--text-accent-color);
    }
  }
  &.mat-warn {
    color: var(--text-warn-color);
    background-color: rgba(var(--warn-color-rgb), 1);
    .mat-simple-snackbar-action {
      color: var(--text-warn-color);
    }
  }
}
.myPanelClass {
  margin-top: 30px !important;
}
tr.cdk-drag-preview {
  // display: flex;
  // flex-direction: row;
  // justify-content: flex-start;
  // gap: 24px;
  // align-items: center;
  background-color: white;
  td,
  th {
    // max-width: 30vw;
    padding-left: 12px;
    padding-right: 12px;
    white-space: nowrap;
    &:first-of-type {
      padding-left: 32px;
      @media screen and (max-width: 600px) {
        padding-left: 16px;
      }
    }
    &:last-of-type {
      padding-right: 32px;
      @media screen and (max-width: 600px) {
        padding-right: 16px;
      }
    }
  }
  td.fit-content {
    width: 1% !important;
  }
  tr.mat-header-row {
    height: 48px;
  }
  tr.cdk-drag-placeholder {
    display: table-row;
  }

  ::ng-deep {
    td.mat-table-sticky-border-elem-right,
    th.mat-table-sticky-border-elem-right:not(:empty) {
      border-left: 1px solid var(--border-color, lightgrey);
    }

    th.mat-table-sticky-border-elem-right:empty {
      background-color: transparent;
      border-bottom-color: #e2e2e2;
    }

    td.mat-table-sticky-border-elem-left,
    th.mat-table-sticky-border-elem-left:not(:empty) {
      border-right: 1px solid var(--border-color, lightgrey);
    }

    th.mat-table-sticky-border-elem-left:empty {
      background-color: transparent;
      border-bottom-color: #e2e2e2;
    }

    td.mat-cell {
      &.mat-table-sticky {
        z-index: 10 !important;
      }

      > a,
      > p,
      > mat-icon {
        vertical-align: middle;
      }

      > mat-icon {
        width: 20px;
        height: 20px;
        font-size: 20px;
      }

      > a {
        margin: 0;
        overflow: hidden;
        text-decoration: none;
        text-overflow: ellipsis;
        color: var(--primary-color);

        &:hover {
          text-decoration: underline;
        }
      }

      > p,
      > h1,
      > h2,
      > h3,
      > h4,
      > h5,
      > h6 {
        margin: 0;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      ::ng-deep mat-chip-list .mat-chip-list-wrapper {
        flex-wrap: nowrap;
      }
    }
  }

  td {
    display: table-cell !important;
    border-bottom: none;
  }
}

/* based on angular-toastr css https://github.com/Foxandxss/angular-toastr/blob/cb508fe6801d6b288d3afc525bb40fee1b101650/dist/angular-toastr.css */

/* position */
.toast-center-center {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.toast-top-center {
  top: 0;
  right: 0;
  width: 100%;
}
.toast-bottom-center {
  bottom: 0;
  right: 0;
  width: 100%;
}
.toast-top-full-width {
  top: 0;
  right: 0;
  width: 100%;
}
.toast-bottom-full-width {
  bottom: 0;
  right: 0;
  width: 100%;
}
.toast-top-left {
  top: 12px;
  left: 12px;
}
.toast-top-right {
  top: 12px;
  right: 12px;
}
.toast-bottom-right {
  right: 12px;
  bottom: 12px;
}
.toast-bottom-left {
  bottom: 12px;
  left: 12px;
}

/* toast styles */
.toast-title {
  font-weight: bold;
}
.toast-message {
  word-wrap: break-word;
}
.toast-message a,
.toast-message label {
  color: #ffffff;
}
.toast-message a:hover {
  color: #cccccc;
  text-decoration: none;
}
.toast-close-button {
  position: relative;
  right: -0.3em;
  top: -0.3em;
  float: right;
  font-size: 20px;
  font-weight: bold;
  color: #ffffff;
  text-shadow: 0 1px 0 #ffffff;
  /* opacity: 0.8; */
}
.toast-close-button:hover,
.toast-close-button:focus {
  color: #000000;
  text-decoration: none;
  cursor: pointer;
  opacity: 0.4;
}
/*Additional properties for button version
 iOS requires the button element instead of an anchor tag.
 If you want the anchor version, it requires `href="#"`.*/
button.toast-close-button {
  padding: 0;
  cursor: pointer;
  background: transparent;
  border: 0;
}
.toast-container {
  pointer-events: none;
  position: fixed;
  z-index: 999999;
}
.toast-container * {
  box-sizing: border-box;
}
.toast-container .ngx-toastr {
  position: relative;
  overflow: hidden;
  margin: 0 0 6px;
  padding: 15px 15px 15px 50px;
  width: 300px;
  border-radius: 3px 3px 3px 3px;
  background-position: 15px center;
  background-repeat: no-repeat;
  background-size: 24px;
  box-shadow: 0 0 12px #999999;
  color: #ffffff;
}
.toast-container .ngx-toastr:hover {
  box-shadow: 0 0 12px #000000;
  opacity: 1;
  cursor: pointer;
}
/* https://github.com/FortAwesome/Font-Awesome-Pro/blob/master/advanced-options/raw-svg/regular/info-circle.svg */
.toast-info {
  background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHZpZXdCb3g9JzAgMCA1MTIgNTEyJyB3aWR0aD0nNTEyJyBoZWlnaHQ9JzUxMic+PHBhdGggZmlsbD0ncmdiKDI1NSwyNTUsMjU1KScgZD0nTTI1NiA4QzExOS4wNDMgOCA4IDExOS4wODMgOCAyNTZjMCAxMzYuOTk3IDExMS4wNDMgMjQ4IDI0OCAyNDhzMjQ4LTExMS4wMDMgMjQ4LTI0OEM1MDQgMTE5LjA4MyAzOTIuOTU3IDggMjU2IDh6bTAgMTEwYzIzLjE5NiAwIDQyIDE4LjgwNCA0MiA0MnMtMTguODA0IDQyLTQyIDQyLTQyLTE4LjgwNC00Mi00MiAxOC44MDQtNDIgNDItNDJ6bTU2IDI1NGMwIDYuNjI3LTUuMzczIDEyLTEyIDEyaC04OGMtNi42MjcgMC0xMi01LjM3My0xMi0xMnYtMjRjMC02LjYyNyA1LjM3My0xMiAxMi0xMmgxMnYtNjRoLTEyYy02LjYyNyAwLTEyLTUuMzczLTEyLTEydi0yNGMwLTYuNjI3IDUuMzczLTEyIDEyLTEyaDY0YzYuNjI3IDAgMTIgNS4zNzMgMTIgMTJ2MTAwaDEyYzYuNjI3IDAgMTIgNS4zNzMgMTIgMTJ2MjR6Jy8+PC9zdmc+');
}
/* https://github.com/FortAwesome/Font-Awesome-Pro/blob/master/advanced-options/raw-svg/regular/times-circle.svg */
.toast-error {
  background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHZpZXdCb3g9JzAgMCA1MTIgNTEyJyB3aWR0aD0nNTEyJyBoZWlnaHQ9JzUxMic+PHBhdGggZmlsbD0ncmdiKDI1NSwyNTUsMjU1KScgZD0nTTI1NiA4QzExOSA4IDggMTE5IDggMjU2czExMSAyNDggMjQ4IDI0OCAyNDgtMTExIDI0OC0yNDhTMzkzIDggMjU2IDh6bTEyMS42IDMxMy4xYzQuNyA0LjcgNC43IDEyLjMgMCAxN0wzMzggMzc3LjZjLTQuNyA0LjctMTIuMyA0LjctMTcgMEwyNTYgMzEybC02NS4xIDY1LjZjLTQuNyA0LjctMTIuMyA0LjctMTcgMEwxMzQuNCAzMzhjLTQuNy00LjctNC43LTEyLjMgMC0xN2w2NS42LTY1LTY1LjYtNjUuMWMtNC43LTQuNy00LjctMTIuMyAwLTE3bDM5LjYtMzkuNmM0LjctNC43IDEyLjMtNC43IDE3IDBsNjUgNjUuNyA2NS4xLTY1LjZjNC43LTQuNyAxMi4zLTQuNyAxNyAwbDM5LjYgMzkuNmM0LjcgNC43IDQuNyAxMi4zIDAgMTdMMzEyIDI1Nmw2NS42IDY1LjF6Jy8+PC9zdmc+');
}
/* https://github.com/FortAwesome/Font-Awesome-Pro/blob/master/advanced-options/raw-svg/regular/check.svg */
.toast-success {
  background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHZpZXdCb3g9JzAgMCA1MTIgNTEyJyB3aWR0aD0nNTEyJyBoZWlnaHQ9JzUxMic+PHBhdGggZmlsbD0ncmdiKDI1NSwyNTUsMjU1KScgZD0nTTE3My44OTggNDM5LjQwNGwtMTY2LjQtMTY2LjRjLTkuOTk3LTkuOTk3LTkuOTk3LTI2LjIwNiAwLTM2LjIwNGwzNi4yMDMtMzYuMjA0YzkuOTk3LTkuOTk4IDI2LjIwNy05Ljk5OCAzNi4yMDQgMEwxOTIgMzEyLjY5IDQzMi4wOTUgNzIuNTk2YzkuOTk3LTkuOTk3IDI2LjIwNy05Ljk5NyAzNi4yMDQgMGwzNi4yMDMgMzYuMjA0YzkuOTk3IDkuOTk3IDkuOTk3IDI2LjIwNiAwIDM2LjIwNGwtMjk0LjQgMjk0LjQwMWMtOS45OTggOS45OTctMjYuMjA3IDkuOTk3LTM2LjIwNC0uMDAxeicvPjwvc3ZnPg==');
}
/* https://github.com/FortAwesome/Font-Awesome-Pro/blob/master/advanced-options/raw-svg/regular/exclamation-triangle.svg */
.toast-warning {
  background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHZpZXdCb3g9JzAgMCA1NzYgNTEyJyB3aWR0aD0nNTc2JyBoZWlnaHQ9JzUxMic+PHBhdGggZmlsbD0ncmdiKDI1NSwyNTUsMjU1KScgZD0nTTU2OS41MTcgNDQwLjAxM0M1ODcuOTc1IDQ3Mi4wMDcgNTY0LjgwNiA1MTIgNTI3Ljk0IDUxMkg0OC4wNTRjLTM2LjkzNyAwLTU5Ljk5OS00MC4wNTUtNDEuNTc3LTcxLjk4N0wyNDYuNDIzIDIzLjk4NWMxOC40NjctMzIuMDA5IDY0LjcyLTMxLjk1MSA4My4xNTQgMGwyMzkuOTQgNDE2LjAyOHpNMjg4IDM1NGMtMjUuNDA1IDAtNDYgMjAuNTk1LTQ2IDQ2czIwLjU5NSA0NiA0NiA0NiA0Ni0yMC41OTUgNDYtNDYtMjAuNTk1LTQ2LTQ2LTQ2em0tNDMuNjczLTE2NS4zNDZsNy40MTggMTM2Yy4zNDcgNi4zNjQgNS42MDkgMTEuMzQ2IDExLjk4MiAxMS4zNDZoNDguNTQ2YzYuMzczIDAgMTEuNjM1LTQuOTgyIDExLjk4Mi0xMS4zNDZsNy40MTgtMTM2Yy4zNzUtNi44NzQtNS4wOTgtMTIuNjU0LTExLjk4Mi0xMi42NTRoLTYzLjM4M2MtNi44ODQgMC0xMi4zNTYgNS43OC0xMS45ODEgMTIuNjU0eicvPjwvc3ZnPg==');
}
.toast-container.toast-top-center .ngx-toastr,
.toast-container.toast-bottom-center .ngx-toastr {
  width: 300px;
  margin-left: auto;
  margin-right: auto;
}
.toast-container.toast-top-full-width .ngx-toastr,
.toast-container.toast-bottom-full-width .ngx-toastr {
  width: 96%;
  margin-left: auto;
  margin-right: auto;
}
.ngx-toastr {
  background-color: #030303;
  pointer-events: auto;
}
.toast-success {
  background-color: #51a351;
}
.toast-error {
  background-color: #bd362f;
}
.toast-info {
  background-color: #2f96b4;
}
.toast-warning {
  background-color: #f89406;
}
.toast-progress {
  position: absolute;
  left: 0;
  bottom: 0;
  height: 4px;
  background-color: #000000;
  opacity: 0.4;
}
/* Responsive Design */
@media all and (max-width: 240px) {
  .toast-container .ngx-toastr.div {
    padding: 8px 8px 8px 50px;
    width: 11em;
  }
  .toast-container .toast-close-button {
    right: -0.2em;
    top: -0.2em;
  }
}
@media all and (min-width: 241px) and (max-width: 480px) {
  .toast-container .ngx-toastr.div {
    padding: 8px 8px 8px 50px;
    width: 18em;
  }
  .toast-container .toast-close-button {
    right: -0.2em;
    top: -0.2em;
  }
}
@media all and (min-width: 481px) and (max-width: 768px) {
  .toast-container .ngx-toastr.div {
    padding: 15px 15px 15px 50px;
    width: 25em;
  }
}
