@mixin components-theme($theme) {
}

.flex_form_control {
  display: flex;
  gap: 8px;
}

.flex_form_control_child {
  flex: 1;
}
